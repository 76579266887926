<template>
  <div class="ProjectManagementPage" :class="{ProjectManagementPage1:b==true}">
     <!-- <div class="ProjectManagementPage_head">
         <headassembly></headassembly>
     </div> -->
      <!-- <div class="ProjectManagementPage_mbx">
        <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item  @click.native="homePage" style="color: #69696A;opacity: 0.4;">首页</el-breadcrumb-item>
              <el-breadcrumb-item style="color: #69696A;">全部项目</el-breadcrumb-item>
        </el-breadcrumb>
      </div> -->
    <div class="ProjectManagementPage_box">
      <!-- <div class="ProjectManagementPage_top">
        <h2><span style="margin-left:20px;"></span><span>全部项目</span></h2>
        <h3>
          <el-button type="primary" icon="el-icon-plus" @click="addproject">创建项目</el-button>
        </h3>
      </div> -->
      <div class="ProjectManagementPage_bottom">
        <el-table
          style="width: 100%;margin:0 auto;"
          height="810px"
          :header-cell-style="{
            color: '#333333',
            backgroundColor: '#FBFBFD',
            'text-align':'center'
          }"
          :data="tableData"
          row-key="Id"
          border
          lazy
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          :indent="30"
         >
          <el-table-column
            label="项目名称"
            min-width="168"
          >
          <template slot-scope="scope">
                <span class="biaoqian1" v-show="scope.row.TopicClass==2">课题</span>
                <span class="biaoqian2" v-show="scope.row.TopicClass==3">子课题</span>
                <span @click="Projectdetails(scope.$index, scope.row)">{{scope.row.TopicName}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="Remarks"
            label="研究内容"
            min-width="450"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Principal"
            label="负责人"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="UserName"
            label="管理员"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="SerialNumber"
            label="序号"
            min-width="80"
          >
          </el-table-column>
          <el-table-column label="起止时间" min-width="120" align="center">
            <template slot-scope="scope">
                <p>{{scope.row.StartDate.substr(0,10)}}</p>
                <p>~</p>
                <p>{{scope.row.EndDate.substr(0,10)}}</p>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="FlowName"
            label="项目阶段"
            min-width="120"
          >
          </el-table-column>
          <!-- <el-table-column
            align="center"
            prop="TopicFlagText"
            label="运行状态"
            min-width="120"
          >
          </el-table-column> -->
      <el-table-column label="运行状态" min-width="100" align="center">
            <template slot-scope="scope">
                 <p><span style="display:inline-block;width:10px;height:10px;border-radius:50%;background:#3388FF;margin-right:6px"></span>{{scope.row.TopicFlagText}}</p>
            </template>
      </el-table-column>
      <el-table-column label="审核状态" min-width="100" align="center">
            <template slot-scope="scope">
                 <p><img style="vertical-align:middle;margin-right:6px" src="../../../assets/image/touxiang.png">{{scope.row.TopicFlagText}}</p>
            </template>
      </el-table-column>
          <!-- <el-table-column
            align="center"
            prop="CheckText"
            label="审核状态"
            min-width="120"
          >
          </el-table-column> -->
          <el-table-column label="操作" min-width="230">
            <!-- <template slot-scope="scope">
              <el-link v-show="scope.row.flag==1"
                icon="el-icon-circle-plus-outline"
                style="margin-right: 10px; color: #3978e7;font-size: 16px;"
              @click="addtopic(scope.$index, scope.row)"
              >添加课题</el-link>
               <el-link v-show="scope.row.flag==2"
                icon="el-icon-circle-plus-outline"
                style="margin-right: 10px; color: #3978e7;font-size: 16px;"
              @click="addtopic(scope.$index, scope.row)"
              >添加子课题</el-link>
              <el-link
                icon="el-icon-edit"
                style="margin-right: 10px;color: #3978e7;font-size: 16px;"
                @click="modifytopic(scope.$index, scope.row)"
              >修改项目</el-link
              >
              <el-link
                icon="el-icon-tickets"
                style="margin-right: 10px; color: #3978e7;font-size: 16px;"
                @click="Projectdetails(scope.$index, scope.row)"
              >项目详情</el-link
              >
              <el-link
                icon="el-icon-edit-outline"
                style="margin-right: 10px;color: #3978e7;font-size: 16px;"
              @click="editstage(scope.$index, scope.row)"
              >编辑阶段</el-link
              >
              <el-link
                icon="el-icon-delete"
                style="color: #3978e7;font-size: 16px;"
                @click="deletetopic(scope.$index, scope.row)"
              >删除</el-link
              >
            </template> -->
           <template slot-scope="scope">
                 <div style="margin-bottom: 6px;color: #3978E7;display: flex;justify-content: center">
                   <img src="../../../assets/image/jia11.png" style="margin-top: 4px;margin-right:4px;width: 16px;height: 16px" v-show="scope.row.flag==1"  @click="addtopic(scope.$index, scope.row)" class="p-hover">
                   <span @click="addtopic(scope.$index, scope.row)" style="margin-right:16px" v-show="scope.row.flag==1" class="p-hover">添加课题</span>
                   <img src="../../../assets/image/jia11.png" style="margin-top: 4px;margin-right:4px;width: 16px;height: 16px" v-show="scope.row.flag==2"  @click="addtopic(scope.$index, scope.row)" class="p-hover">
                   <span @click="addtopic(scope.$index, scope.row)" style="margin-right:16px" v-show="scope.row.flag==2" class="p-hover">添加子课题</span>
<!--                   <img src="../../../assets/image/jia14.png" style="vertical-align:middle;margin-right:4px"  @click="modifytopic(scope.$index, scope.row)"><span @click="modifytopic(scope.$index, scope.row)">修改信息</span>-->
                   <edit-project :key="scope.row.Id" :editProjectId="scope.row.Id" @project-edit="projectRefresh" :TopicClass="scope.row.TopicClass"></edit-project>
                 </div>
                  <p style="text-align: center;margin-bottom: 6px;;color: #3978E7;">
                   <img src="../../../assets/image/jia12.png" style="vertical-align:middle;margin-right:4px"  @click="Projectdetails(scope.$index, scope.row)" class="p-hover">
                    <span style="margin-right:16px"  @click="Projectdetails(scope.$index, scope.row)" class="p-hover">查看详情</span>
                   <img src="../../../assets/image/jia15.png" style="vertical-align:middle;margin-right:4px"  @click="editstage(scope.$index, scope.row)" class="p-hover">
                    <span @click="editstage(scope.$index, scope.row)" class="p-hover">编辑阶段</span>
                 </p>
                  <p style="color: #3978E7;" class="p-hover">
                   <img src="../../../assets/image/jia13.png" style="vertical-align:-2px;margin-right:4px;margin-left:14px;" @click="deletetopic(scope.$index, scope.row)"><span @click="deletetopic(scope.$index, scope.row)">删除</span>
                 </p>
           </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 弹框 -->

    <!-- 新增项目弹框 -->
    <div class="dialog_add">
      <el-dialog :visible.sync="addprojectdialog">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">{{title}}</span>
        </div>
        <div class="body">
          <div class="body_left">
            <el-form label-width="160px">
              <el-form-item :label="TopicTypename">
                <el-select placeholder="请选择" v-model="TopicType">
                  <el-option :label="item.TypeName" :value="item.Id" v-for="(item,index) in ProjectTypelist" :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="TopicModename">
                <el-select placeholder="请选择" v-model="TopicMode">
                  <el-option :label="item.Name" :value="item.Id" v-for="(item,index) in ProjectModelist" :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="主题方向">
                <el-input v-model="TopicDirection"></el-input>
              </el-form-item>
              <el-form-item :label="TopicNamename">
                <el-input v-model="TopicName"></el-input>
              </el-form-item>
              <el-form-item :label="TopicCodename">
                <el-input v-model="TopicCode"></el-input>
              </el-form-item>
              <el-form-item label="项目牵头单位:" v-show="show">
                <el-input v-model="HospitalName" disabled></el-input>
              </el-form-item>
              <el-form-item :label="TopicHospitalName" v-show="!show">
                  <el-autocomplete
                  class="inline-input"
                  v-model="HospitalName"
                  value-key="HospitalName"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="管理员">
                <el-input  v-model="Topicadministrators" class="input1" disabled v-show="show"></el-input>
                <span class="fromHeader" v-show="!show">
                <el-select v-model="TopicPi" placeholder="请选择" filterable>
                    <el-option class="input2"
                      :label="item.UserName"
                      :value="item.Id"
                      v-for="(item, index) in allpersonnellist"
                      :key="index"
                    ></el-option>
                  </el-select>
                </span>
                 <span class="el-icon-circle-plus-outline" @click="Newmembers" v-show="!show"></span>
                <span class="span1">负责人</span>
                <el-input  v-model="Principal" class="input1"></el-input>
              </el-form-item>
               <el-form-item :label="label" v-show="!show">
                <el-input v-model="SerialNumber"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="body_right">
            <el-form label-width="150px">
              <el-form-item label="项目经费:">
                <el-input v-model="Funds"></el-input>
                <span>万元</span>
              </el-form-item>
              <el-form-item label="起止时间:">
                <div class="block">
                  <el-date-picker
                    v-model="timelist"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item label="研究内容:">
                <el-input v-model="Remarks" type="textarea"></el-input>
              </el-form-item>
              <el-form-item label="考核指标:">
                <el-input v-model="Assess" type="textarea"></el-input>
              </el-form-item>
              <el-form-item label="附件:">
                <el-upload
                  class="upload-demo"
                  ref="newupload"
                  accept=".xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.xlsx,"
                  multiple
                  action="#"
                  :file-list="fileList"
                  :auto-upload="false"
                  :on-change="oncheng"
                >
                  <el-button size="medium" type="primary">点击上传</el-button>
                </el-upload>
              </el-form-item>
              <el-form-item label="附件备注:">
                <el-input v-model="FileRemarks" type="textarea"></el-input>
              </el-form-item>
              <el-form-item label="是否审核标注:">
                <el-radio-group v-model="IsCheck">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="btn">
          <button @click="addprojectdialog=false">取消</button>
          <button @click="Createproject">创建</button>
        </div>
      </el-dialog>
    </div>
    <!-- 编辑阶段弹框 -->
    <div class="addstagedialog">
      <el-dialog
        :visible.sync="addstagedialog">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">编辑阶段</span>
        </div>
        <div class="body">
          <div class="body_table">
            <el-table
              :data="membertableData"
              style="width: 100%"
              height="380"
              @selection-change="allselect"
              :header-cell-style="{
                            color: '#333333',
                            backgroundColor: '#FBFBFD',
                            }">
              <el-table-column
                type="selection"
                width="55">
              </el-table-column>
              <el-table-column
                label="阶段名称"
                prop="FlowName"
                width="120"
                align="center">
              </el-table-column>
              <el-table-column
                label="开始时间"
                prop="StartDate"
                width="140"
                align="center">
              </el-table-column>
              <el-table-column
                label="结束时间"
                prop="EndDate"
                width="140"
                align="center">
              </el-table-column>
              <el-table-column
                label="阶段说明"
                prop="Remarks"
                width="140"
                align="center">
              </el-table-column>
              <el-table-column label="操作" min-width="100" align="center">
                <template slot-scope="scope">
                  <el-link
                    icon="el-icon-edit"
                    style="margin-right: 10px; color: #3978e7; font-size: 18px"
                    @click="modifystage(scope.$index, scope.row)"
                  >修改</el-link
                  >
                  <el-link
                    icon="el-icon-delete"
                    style="color: #3978e7; font-size: 18px"
                    @click="deletestage(scope.$index, scope.row)"
                  >删除</el-link
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
<!--    </div>-->
    <div class="btn">
        <button @click="addstage">新增阶段</button>
        <button @click="Phasepositionexchange">阶段位置交换</button>
        <!-- <button>取消</button>
        <button>修改</button> -->
    </div>
    </el-dialog>
  </div>
  <!-- 删除阶段弹框 -->
  <div class="stagedeletedialog">
    <el-dialog
      :visible.sync="stagedeletedialog">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">提示</span>
      </div>
      <div class="body">
        <span>!</span><span>此操作将永久删除该阶段,是否继续？</span>
      </div>
      <div class="btn">
        <button @click="stagedeletedialog=false">取消</button>
        <button @click="OKstagedelete">确定</button>
      </div>
    </el-dialog>
  </div>
  <!-- 新增阶段弹框 -->
  <div class="newlyaddedstagedialog">
    <el-dialog
      :visible.sync="newlyaddedstagedialog">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">新增阶段</span>
      </div>
      <div class="body">
        <el-form  label-width="100px">
          <el-form-item label="阶段名称:">
            <el-input v-model="FlowName"></el-input>
          </el-form-item>
          <el-form-item label="阶段时间:">
            <el-date-picker
              v-model="Flowtimelist"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="阶段说明:">
            <el-input type="textarea" v-model="FlowRemarks"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn">
        <button @click="newlyaddedstagedialog=false">取消</button>
        <button @click="addokstage">确定</button>
      </div>
    </el-dialog>
  </div>
  <!-- 修改编辑弹框 -->
  <div class="modifystagedialog">
    <el-dialog
      :visible.sync="modifystagedialog">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">修改阶段</span>
      </div>
      <div class="body">
        <el-form  label-width="100px">
          <el-form-item label="阶段名称:">
            <el-input v-model="FlowName"></el-input>
          </el-form-item>
          <el-form-item label="阶段时间:">
            <el-date-picker
              v-model="Flowtimelist"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="阶段说明:">
            <el-input type="textarea" v-model="FlowRemarks"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="btn">
        <button @click="modifystagedialog=false">取消</button>
        <button @click="stageModifyOK">确定</button>
      </div>
    </el-dialog>
  </div>
   <!-- 选择管理员弹框 -->
  <div class="administratorsdialog">
    <el-dialog
      :visible.sync="administratorsdialog">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">请选择管理员</span>
      </div>
      <div class="body">
         <el-select  placeholder="请选择" v-model="TopicPi" @change="personnelchange">
          <el-option :label="item.UserName" :value="item.Id" v-for="(item,index) in allpersonnellist" :key="index"></el-option>
        </el-select>
      </div>
      <div class="btn">
        <button @click="OKcancel">取消</button>
        <button @click="administratorsdialog=false">确定</button>
      </div>
    </el-dialog>
  </div>
   <!-- 删除项目弹框 -->
   <div class="deleteprojectdialog">
    <el-dialog
      :visible.sync="deleteprojectdialog">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">提示</span>
      </div>
      <div class="body">
        <span>!</span><span>此操作将永久删除该项目,是否继续？</span>
      </div>
      <div class="btn">
        <button @click="deleteprojectdialog=false">取消</button>
        <button @click="projectDeleteOK">确定</button>
      </div>
    </el-dialog>
  </div>
  <!-- 修改项目弹框 -->
  <div class="Modifyprojectdialog">
    <el-dialog
      :visible.sync="Modifyprojectdialog">
      <div slot="title" class="header-title">
        <span class="title-name"></span>
        <span class="title-age">修改信息</span>
      </div>
      <div class="body">
            <el-form label-width="160px">
              <el-form-item :label="TopicTypename">
                <el-select placeholder="请选择" v-model="TopicType">
                  <el-option :label="item.TypeName" :value="item.Id" v-for="(item,index) in ProjectTypelist" :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="TopicModename">
                <el-select placeholder="请选择" v-model="TopicMode">
                  <el-option :label="item.Name" :value="item.Id" v-for="(item,index) in ProjectModelist" :key="index"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="主题方向">
                <el-input v-model="TopicDirection"></el-input>
              </el-form-item>
              <el-form-item :label="TopicNamename">
                <el-input v-model="TopicName"></el-input>
              </el-form-item>
              <el-form-item :label="TopicCodename">
                <el-input v-model="TopicCode"></el-input>
              </el-form-item>
              <el-form-item label="研究内容:">
                <el-input v-model="Remarks" type="textarea"></el-input>
              </el-form-item>
              <el-form-item label="考核指标:">
                <el-input v-model="Assess" type="textarea"></el-input>
              </el-form-item>
            <el-form-item label="起始时间:">
                <div class="block">
                  <el-date-picker
                    v-model="timelist"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-form>
      </div>
      <div class="btn">
        <button @click="Modifyprojectdialog=false">取消</button>
        <button @click="OKModifyproject">确定</button>
      </div>
    </el-dialog>
  </div>
  <!-- 加人到系统弹框 -->
   <div class="dialog_add1">
        <el-dialog
          :visible.sync="dialogVisible"
         >
          <div slot="title" class="header-title">
            <span class="title-name"></span>
            <span class="title-age">新增成员</span>
          </div>
          <div class="body">
            <div class="body-left">
              <el-form label-width="150px">
                <el-form-item label="登录名:">
                  <el-input v-model="LoginName" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="密码:">
                  <el-input v-model="Password" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="姓名:">
                  <el-input v-model="UserName" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="手机号:">
                  <el-input v-model="PhoneNumber" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="身份证:">
                  <el-input v-model="IdentityCard" placeholder="请输入" @blur="IdBlur"></el-input>
                </el-form-item>
                <el-form-item label="性别:">
                  <el-radio-group v-model="Sex">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="0">女</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
            <div class="body-right">
              <el-form label-width="150px">
                <el-form-item label="微信:">
                  <el-input v-model="WX" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="邮箱:">
                  <el-input v-model="EMail" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="职称:">
                  <el-select  placeholder="请选择" v-model="Title">
                    <el-option :label="item.Name" :value="item.Id" v-for="(item,index) in TitleArray" :key="index"></el-option>
                  </el-select>
                </el-form-item>
                 <el-form-item label="医院:">
                  <el-autocomplete
                  class="inline-input"
                  v-model="HospitalName1"
                  value-key="HospitalName"
                  :fetch-suggestions="querySearch1"
                  placeholder="请输入内容"
                  :trigger-on-focus="false"
                  @select="handleSelect1"
                ></el-autocomplete>
                </el-form-item>
                <el-form-item label="科室:">
                  <el-select v-model="DepartmentId" placeholder="请选择" filterable>
                    <el-option
                      :label="item.DeptName"
                      :value="item.Id"
                      v-for="(item, index) in Department"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="角色:">
                  <el-select v-model="RoleId" placeholder="请选择">
                    <el-option
                      :label="item.RoleName"
                      :value="item.Id"
                      v-for="(item, index) in option"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div class="btn">
            <button  @click="dialogVisible = false">取消</button>
            <button @click="OKmember">确定</button>
          </div>
        </el-dialog>
      </div>
  <!-- 弹框 -->
  </div>
</template>
<script>
import editProject from "@/components/editProject";
import api from "../../../api/url.js";
import {mapState} from "vuex"
export default {
  data() {
    return {
      HospitalName1:"",//医院名字显示
      HospitalId:"",//医院id
      Title:"",//职称
      DepartmentId:"",//科室
      RoleId:"",//角色
      LoginName:"",//登录名
      Password:"",//密码
      UserName:"",//姓名
      PhoneNumber:"",//手机号
      IdentityCard:"",//身份证
      Sex:1,//性别
      WX:"",//微信
      QQ:"",//qq
      EMail:"",//邮箱
      option:[],//角色数组
      TitleArray:[],//职称数组
      Department:[],//科室数组
      total:0,
      listQuery: {
        pageNum: 1,
        pageSize: 10,
      },
      dialogVisible:false,
      value1:[],
      onlyId:"",//储存项目id
      stageId:"",//储存阶段id
      show:true,
      label:"课题序号:",
      title: "创建项目",
      TopicTypename: "项目类型:",
      TopicModename: "项目方式:",
      TopicDirectionname: "主题方向:",
      TopicNamename: "项目名称:",
      TopicCodename: "项目编号:",
      TopicHospitalName:"课题承担单位:",
      fileList: [],
      personnel:"",
      Pid: 0,
      ProjectId:0,
      TopicPi:"",//项目管理员id
      Topicadministrators:"",//项目管理员名字，只做显示
      TopicType: "", //项目类型
      TopicMode: "", //项目方式
      TopicDirection: "", //主题方向
      TopicName: "", //项目名称
      TopicCode: "", //项目编号
      HospitalName: "", //项目牵头单位,只做显示
      Principal: "", //负责人
      timelist: [], //时间数组
      Remarks: "", //研究内容
      Assess: "", //考核指标
      FileRemarks: "", //附件备注
      IsCheck: 1, //是否审核标注
      SerialNumber:"",//课题,子课题编号
      Funds:"",//项目经费
      addprojectdialog: false, //打开项目
      addstagedialog: false, //打开编辑阶段
      stagedeletedialog: false, //打开删除阶段
      newlyaddedstagedialog: false, //打开新增阶段
      modifystagedialog: false, //打开修改阶段
      administratorsdialog:false,//打开选择管理员
      deleteprojectdialog:false,//打开删除项目
      Modifyprojectdialog:false,//打开修改项目
      ProjectTypelist: [], //项目类型数组
      ProjectModelist: [], //项目方式数组
      allrolelist: [], //所有角色数组
      allpersonnellist: [], //所有人员数组,通过医院id获取的
      Storepersonnellist: [], //储存勾选了的人员数组
      filearr: [], //储存是否上传文件
      membertableData:[],//储存基础阶段数据
      tableData:[],//储存全部课题数据
      FlowName:"",//阶段名称
      Flowtimelist:[],//阶段时间
      FlowRemarks:"",//阶段说明
      stagearr:[],//储存阶段位置交换数组
    };
  },
  computed: mapState(["pmid","b"]),
  created() {
    this.projectallTable();//全部项目数据
    this.$store.commit("nodekeydata", 1);
  },
  components: {
    editProject
  },
  methods: {
    projectRefresh(data) { // 刷新
      if(data) {
        this.projectallTable()
      }
    },
       IdBlur() {
      const regId = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/;
      if (!regId.test(this.IdentityCard)) {
        console.log('身份证不正确')
      } else {
        if (this.IdentityCard.substr(16, 1)% 2 == 1){
          this.Sex = 1
        } else {
          this.Sex = 0
        }
      }
    },
        homePage() {  // 到首页的高亮
          this.$store.commit("menustate", 1);
          this.$router.push({name:"HomePage"})
        },
        OKmember(){
          console.log(this.HospitalId);
        let parame={
           projectId:0,
           LoginName:this.LoginName,
           Password:this.Password,
           UserName:this.UserName,
           PhoneNumber:this.PhoneNumber,
           IdentityCard:this.IdentityCard,
           NickName:this.NickName,
           Sex:this.Sex,
           WX:this.WX,
           QQ:"",
           EMail:this.EMail,
           Title:this.Title,
           HospitalId:this.HospitalId,
           DepartmentId:this.DepartmentId,
           RoleId:"",
           }
           api.addUserData(parame).then(res=>{
              console.log(res,"1111111");
              if(res.data.Status==1){
                  this.dialogVisible=false
                  this.HospitalName=this.HospitalName1
                      api.hospitalIdsData(this.HospitalId).then(res=>{
                        if(res.data.Status==1){
                          this.allpersonnellist=res.data.Entity
                          this.LoginName=""
                          this.Password=""
                          this.UserName=""
                          this.PhoneNumber=""
                          this.IdentityCard=""
                          this.NickName=""
                          this.WX=""
                          this.EMail=""
                          this.Title=""
                          this.DepartmentId=""
                          this.RoleId=""
                        }else{
                          alert(res.data.Message)
                        }
                    console.log(res,"人员");
                  })
              }else{
                    alert(res.data.Message)
              }
           })
        },
        handleSelect1(item){//根据医院id查出科室
        console.log(item,"根据医院id查出科室");
         this.HospitalId=item.Id
         let parame={
           HospitalId:item.Id
         }
       api.getDepartmentInfoData(parame).then(res=>{
         if(res.data.Status==1){
           this.Department=res.data.Entity
         }else{
           alert(res.data.Message)
         }
          console.log(res,"科室");
      })
    },
        titlelist(){//职称数组
        let parame="JobTitle"
        api.titleData(parame).then(res=>{
               if(res.data.Status==1){
                   this.TitleArray=res.data.Entity
               }else{
                  alert(res.data.Message)
               }
        })
    },
      allrolelist1(){//角色
      api.allroleData().then(res=>{
        if(res.data.Status==1){
          this.option=res.data.Entity
          }else{
            alert(res.data.Message)
        }
        console.log(res,"成员");
      })
    },
        querySearch1(queryString, cb){//查询出医院
          console.log(queryString, cb);
          let parame={
              key:queryString
          }
          api.hospitalData(parame).then(res=>{
               console.log(res);
               if(res.data.Status==1){
                 cb(res.data.Entity)
               }else{
                 alert(res.data.Message)
               }
          })
    },
    Newmembers(){
      this.dialogVisible=true
      this.titlelist()
      this.allrolelist1()
    },
    // administratorSelect(row){//管理员id
    //     console.log(row);
    // },
    allselect(row){
      this.stagearr=row
       console.log(row);
    },
    Phasepositionexchange(){//阶段位置交换
      if (this.stagearr.length == 2) {
        let parame = {
          Id1: this.stagearr[0].Id,
          Id2: this.stagearr[1].Id,
        };
        api.conversionData(parame).then(res=> {
          if (res.data.Status == 1) {
            this.$message({ message: res.data.Message, type: "success" });
            this.Stepslist(this.onlyId)
          }
          console.log(res);
        });
      } else {
        this.$message({ message: "只能选择两个阶段进行调整", type: "warning" });
      }
    },
    modifytopic(i,row){//打开修改项目
      console.log(row);
      this.timelist=[]
      this.onlyId=row.Id
      this.ProjectTypeSelect()
      this.ProjectModeSelect()
      this.TopicType=row.TopicType
      this.TopicMode=row.TopicMode
      this.TopicDirection=row.TopicDirection
      this.TopicName=row.TopicName
      this.TopicCode=row.TopicCode
      this.Remarks=row.Remarks
      this.Assess=row.Assess
      this.timelist.push(row.StartDate)
      this.timelist.push(row.EndDate)
      this.Modifyprojectdialog=true
    },
    OKModifyproject(){//确定修改项目
    let parame={
        Id:this.onlyId,
        TopicType:this.TopicType,
        TopicMode:this.TopicMode,
        TopicDirection:this.TopicDirection,
        TopicName:this.TopicName,
        TopicCode:this.TopicCode,
        Remarks:this.Remarks,
        Assess:this.Assess,
        StartDate:this.timelist[0],
        EndDate:this.timelist[1],
    }
       api.uptopData(parame).then(res=>{
         console.log(res);
         if(res.data.Status==1){
           this.$message.success("修改成功");
           this.projectallTable()
         }else{
            alert(res.data.Message)
         }
       })
    },
    getList(val){//分页组件事件
      this.listQuery.pageNum=val.page
      this.projectallTable()
    console.log(val,"dhawh");
    },
    stageModifyOK(){//确定修改阶段
       let parame={
           Id:this.stageId,
           TopicId:this.onlyId,
           FlowName:this.FlowName,
           StartDate:this.Flowtimelist[0],
           EndDate:this.Flowtimelist[1],
           Remarks:this.FlowRemarks
        }
        api.ModificationphaseData(parame).then(res=>{
              console.log(res);
              if(res.data.Status==1){
                  this.modifystagedialog=false
                  this.Stepslist(this.onlyId)
                  this.$message.success("修改成功");
              }else{
                 alert(res.data.Message)
              }
        })
    },
    addokstage(){//确定新增阶段
       let parame={
           TopicId:this.onlyId,
           FlowName:this.FlowName,
           StartDate:this.Flowtimelist[0],
           EndDate:this.Flowtimelist[1],
           Remarks:this.FlowRemarks
        }
        api.addstageData(parame).then(res=>{
              console.log(res);
              if(res.data.Status==1){
                 this.newlyaddedstagedialog = false;
                 this.Stepslist(this.onlyId)
                 this.$message.success("新增成功");
              }else{
                this.newlyaddedstagedialog = false;
                  alert(res.data.Message)
              }
        })
    },
    deletetopic(i,row){//打开删除项目弹框
       this.onlyId=row.Id
       this.deleteprojectdialog=true
    },
    projectDeleteOK(){//确定删除项目
       let parame={
         Id:this.onlyId
       }
       api.deleteprojectData(parame).then(res=>{
           console.log(res);
           if(res.data.Status==1){
             this.projectallTable()
             this.$message.success("删除成功");
             this.deleteprojectdialog=false
           }else{
              this.$message({
                message: res.data.Message,
                type: 'warning'
              });
              this.deleteprojectdialog=false
           }
       })
    },
    querySearch(queryString, cb){//查询出医院
          console.log(queryString, cb);
          let parame={
              key:queryString
          }
          api.hospitalData(parame).then(res=>{
               console.log(res);
               if(res.data.Status==1){
                 cb(res.data.Entity)
               }else{
                 alert(res.data.Message)
               }
          })
    },
    handleSelect(item) {//查询出人员
        this.HospitalId=item.Id
        console.log(this.HospitalId,"查询出人员");
        this.HospitalName1=item.HospitalName
        let parame={
           HospitalId:this.HospitalId
         }
       api.getDepartmentInfoData(parame).then(res=>{
         if(res.data.Status==1){
           this.Department=res.data.Entity
         }else{
           alert(res.data.Message)
         }
          console.log(res,"科室");
      })
        // this.TopicPi=""
        // this.Topicadministrators=""
        // this.administratorsdialog=true
        api.hospitalIdsData(item.Id).then(res=>{
            if(res.data.Status==1){
              this.allpersonnellist=res.data.Entity
            }else{
              alert(res.data.Message)
            }
          console.log(res,"人员");
        })
    },
    personnelchange(){//获取课题管理员id和姓名
           console.log(this.TopicPi);
        this.allpersonnellist.forEach(item=>{
             if(item.Id==this.TopicPi){
               this.Topicadministrators=item.UserName
             }
        })
    },
    OKcancel(){//取消人员
      this.TopicPi=""
      this.Topicadministrators=""
      this.administratorsdialog=false
    },
    // dialogclose(){//弹框关闭回调
    //         this.TopicPi=""
    //   this.Topicadministrators=""
    // },
    Projectdetails(i,row){//项目详情按钮
       this.$store.commit("menustate", 3);
       this.$store.commit("SaveId", row.Id);
       this.$store.commit("Guid", row.DataGuid);
       this.$store.commit("treedata", row.Id)  // 保存树节点Id
       this.$store.commit("nodekeydata", row.Id);
       this.$router.push({name:"ScientificResearchProcess"})
    },
    projectallTable() {//项目数据
      this.tableData = []
      let parame = this.pmid==""?sessionStorage.getItem("pmid"):this.pmid
      api.singletopicData(parame).then(res=> {
        console.log(res,"222222");
        if (res.data.Status == 1) {
            this.tableData.push(res.data.ExtraData)
            api.topicallData(this.tableData[0].Id).then(res1=>{//传入项目ID获取课题
            console.log(res1,"3333333");
                  if(res1.data.Status==1){
                         this.$set(this.tableData[0], "flag", 1)
                      if(res1.data.Entity.length!=0){
                        this.$set(this.tableData[0], "children", res1.data.Entity)
                        this.tableData[0].children.forEach(item1=>{
                           api.topicallData(item1.Id).then(res2=>{//传入课题ID获取子课题
                           console.log(res2,"44444444");
                               if(res2.data.Status==1){
                                 this.$set(item1, "flag", 2)
                                 if(res2.data.Entity.length!=0){
                                     this.$set(item1, "children", res2.data.Entity)
                                 }
                               }
                           })
                        })
                      }
                  }
              })
        }
        console.log(this.tableData);
      });
    },
    ProjectTypeSelect() {
      //项目类型
      api.ProjectTypeData().then((res) => {
        if (res.data.Status == 1) {
          this.ProjectTypelist = res.data.Entity;
        } else {
          alert(res.data.Message);
        }
        console.log(res);
      });
    },
    ProjectModeSelect() {
      //项目方式
      api.ProjectModeData("TopicMode").then((res) => {
        if (res.data.Status == 1) {
          this.ProjectModelist = res.data.Entity;
        } else {
          alert(res.data.Message);
        }
        console.log(res);
      });
    },
    Createproject() {
      //创建项目
      if(this.Principal==""){
             alert("项目负责人不能为空")
      }else{
        if (this.filearr.length == 0) {
          //如果数组为空说明没有上传文件
          let parame = {
            TopicPi: this.TopicPi,
            Pid: this.Pid,
            TopicType: this.TopicType,
            TopicMode: this.TopicMode,
            TopicDirection: this.TopicDirection,
            TopicName: this.TopicName,
            TopicCode: this.TopicCode,
            Principal: this.Principal,
            userIds: this.Storepersonnellist,
            StartDate: this.timelist[0],
            EndDate: this.timelist[1],
            Remarks: this.Remarks,
            Assess: this.Assess,
            FileRemarks: this.FileRemarks,
            ProjectId: this.ProjectId,
            IsCheck: this.IsCheck,
            SerialNumber:this.SerialNumber,
          };
          let fd = new FormData();
          fd.append("data", JSON.stringify(parame));
          api.addprojectfileData(fd).then((res) => {
            if (res.data.Status == 1) {
              this.$message({
                message: "创建成功",
                type: "success",
              });
              this.projectallTable()
              this.addprojectdialog=false
            } else {
              alert(res.data.Message);
            }
            console.log(res);
          });
        } else {
            if(this.Principal==""){
                  alert("项目负责人不能为空")
            }else{
              let parame = {
                TopicPi: this.TopicPi,
                Pid: this.Pid,
                TopicType: this.TopicType,
                TopicMode: this.TopicMode,
                TopicDirection: this.TopicDirection,
                TopicName: this.TopicName,
                TopicCode: this.TopicCode,
                Principal: this.Principal,
                userIds: this.Storepersonnellist,
                StartDate: this.timelist[0],
                EndDate: this.timelist[1],
                Remarks: this.Remarks,
                Assess: this.Assess,
                FileRemarks: this.FileRemarks,
                ProjectId: this.ProjectId,
                IsCheck: this.IsCheck,
                SerialNumber:this.SerialNumber,
                Funds:this.Funds
              };
              let fd = new FormData();
              this.filearr.forEach(file => {
                  fd.append('file',file.raw)
              })
              fd.append("data", JSON.stringify(parame));
              api.addprojectfileData(fd).then((res) => {
                if (res.data.Status == 1) {
                  this.$message({
                    message: "创建成功",
                    type: "success",
                  });
                  this.projectallTable()
                  this.addprojectdialog=false
                } else {
                  alert(res.data.Message);
                }
                console.log(res);
              });
              return true;
            }
        }
      }
    },
    // beforeUpload(file) {
    //     if(this.Principal==""){
    //          alert("项目负责人不能为空")
    //   }else{
    //     console.log(file);
    //     let parame = {
    //       TopicPi: this.TopicPi,
    //       Pid: this.Pid,
    //       TopicType: this.TopicType,
    //       TopicMode: this.TopicMode,
    //       TopicDirection: this.TopicDirection,
    //       TopicName: this.TopicName,
    //       TopicCode: this.TopicCode,
    //       Principal: this.Principal,
    //       userIds: this.Storepersonnellist,
    //       StartDate: this.timelist[0],
    //       EndDate: this.timelist[1],
    //       Remarks: this.Remarks,
    //       Assess: this.Assess,
    //       FileRemarks: this.FileRemarks,
    //       ProjectId: this.ProjectId,
    //       IsCheck: this.IsCheck,
    //       SerialNumber:this.SerialNumber,
    //       Funds:this.Funds
    //     };
    //     let fd = new FormData();
    //      this.filearr.forEach(file => {
    //         fd.append('file',file.raw)
    //      })
    //     fd.append("data", JSON.stringify(parame));
    //     api.addprojectfileData(fd).then((res) => {
    //       if (res.data.Status == 1) {
    //         this.$message({
    //           message: "创建成功",
    //           type: "success",
    //         });
    //         this.projectallTable()
    //         this.addprojectdialog=false
    //       } else {
    //         alert(res.data.Message);
    //       }
    //       console.log(res);
    //     });
    //     return true;
    //   }
    // },
    oncheng(file, fileList) {
      this.filearr = fileList;
      console.log(file, fileList);
    },
    addproject() {
      //打开创建项目弹框、
        this.show=true
        this.title="创建项目"
        this.TopicPi=sessionStorage.getItem("Id")
        this.Topicadministrators=sessionStorage.getItem("UserName")//项目管理员
        // this.Principal=sessionStorage.getItem("UserName")//负责人
        this.HospitalName=sessionStorage.getItem("HospitalName")
        this.addprojectdialog = true;
        this.ProjectTypeSelect();
        this.ProjectModeSelect();
        this.TopicType=""
        this.TopicMode=""
        this.TopicDirection=""
        this.TopicName=""
        this.TopicCode=""
        this.timelist=[]
        this.Remarks=""
        this.Assess=""
        this.FileRemarks=""
    },
    editstage(i,row) {
      //打开编辑阶段弹框
      this.addstagedialog = true;
      this.onlyId=row.Id
      let parame=this.onlyId
      this.Stepslist(parame)
    },
    Stepslist(parame){//阶段数据
         api.StepsData(parame).then(res=>{
           console.log(res);
           if(res.data.Status==1){
             this.membertableData = res.data.ExtraData.map(item => {
             if (item.StartDate != null) {
               item.StartDate = item.StartDate.substring(0, 11);
             }
             if (item.EndDate != null) {
               item.EndDate = item.EndDate.substring(0, 11);
             }
             return item;
           });
           }else{
             alert(res.data.Message)
           }
      })
    },
    deletestage(i,row) {//打开阶段删除弹框
      this.stageId=row.Id
      this.stagedeletedialog = true;
    },
    OKstagedelete(){//确定删除阶段
        let parame={
          Id:this.stageId
        }
        api.deletestageData(parame).then(res => {
        if(res.data.Status == 1) {
              this.stagedeletedialog = false;
              this.Stepslist(this.onlyId)
              this.$message.success("删除成功");
        }else{
              alert(res.data.Message)
        }
        console.log(res);
      });
    },
    addstage() {//打开新增阶段弹框
      this.newlyaddedstagedialog = true;
    },
    modifystage(i,row) {//打开修改阶段弹框
      console.log(row);
      this.FlowName=""
      this.Flowtimelist=[]
      this.FlowRemarks=""
      this.FlowName=row.FlowName,
      this.Flowtimelist.push(row.StartDate)
      this.Flowtimelist.push(row.EndDate)
      this.FlowRemarks=row.Remarks,
      this.stageId=row.Id
      this.modifystagedialog = true;
    },
    addtopic(i,row) {
      this.fileList=[]
       if(row.flag==1){
         this.Pid=row.Id
         this.ProjectId=row.Id
         this.TopicDirection=row.TopicDirection
         this.Principal=""
         this.title="创建课题"
         this.label="课题序号"
         this.TopicTypename="课题类型"
         this.TopicModename="课题方式"
         this.TopicNamename="课题名称"
         this.TopicCodename="课题编号"
         this.TopicHospitalName="课题承担单位"
       }else{
         this.Pid=row.Id
         this.ProjectId=row.ProjectId
         this.TopicDirection=row.TopicDirection
         this.Principal=""
         this.title="创建子课题"
         this.label="子课题序号"
         this.TopicTypename="子课题类型"
         this.TopicModename="子课题方式"
         this.TopicNamename="子课题名称"
         this.TopicCodename="子课题编号"
         this.TopicHospitalName="子课题承担单位"
       }
       this.show=false
       this.addprojectdialog=true
       this.ProjectTypeSelect();
       this.ProjectModeSelect();
       this.TopicType=""
       this.TopicMode=""
       this.TopicName=""
       this.TopicCode=""
       this.timelist=[]
       this.Remarks=""
       this.Assess=""
       this.FileRemarks=""
       this.HospitalName=""
       this.TopicPi=""
      //  this.Topicadministrators=sessionStorage.getItem("UserName")//项目管理员
       console.log(i,row);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/mixin.scss";
.biaoqian1{
  text-align: center;
  width: 50px;
  background: #319EFD;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
}
.biaoqian2{
  text-align: center;
  width: 60px;
  background: #FF718E;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  border-radius: 4px;
}
.ProjectManagementPage {
  width: 1700px;
  height: 100%;
  overflow: hidden;
//   background: #F0F7FD;
  transition: width 1s;
  .ProjectManagementPage_box {
    width: 100%;
    height: 95%;
    margin: 0 auto;
    border-radius: 10px;
    background: #fff;
    margin-top: 20px;
  .ProjectManagementPage_bottom {
    width: 100%;
    height: 100%;
    /deep/.el-table{
        border-radius: 10px;
       @include add-size($font_size_16);
    }
    ::-webkit-scrollbar {
      width: 10px; /*对垂直流动条有效*/
      height: 10px; /*对水平流动条有效*/
    }

/*定义滚动条的轨道颜色、内阴影及圆角*/
    ::-webkit-scrollbar-track{
    background-color: #fff;
    border-radius: 3px;
    }

      /*定义滑块颜色、内阴影及圆角*/
      ::-webkit-scrollbar-thumb{
      border-radius: 7px;
      background-color: #1677FF;
      }
  }
  }
}
.ProjectManagementPage1{
   width: 1870px;
}
.dialog_add {
  /deep/.el-dialog {
    width: 1200px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .body {
      height: 520px;
      .body_left {
        float: left;
        .el-form {
          .el-form-item {
            .el-form-item__label {
              @include add-size($font_size_16);
              color: #666666;
            }
            .el-input {
              @include add-size($font_size_16);
              width: 390px;
            }
             .fromHeader .el-select .el-input {
                  width: 160px;
              }
            .input1{
              width: 150px;
              margin-right: 10px;
              @include add-size($font_size_16);
            }
            .span1{
              margin-right: 10px;
              @include add-size($font_size_16);
            }
          }
        }
      }
      .body_right {
        float: left;
        .el-form {
          .el-form-item {
            .el-form-item__label {
              @include add-size($font_size_16);
              color: #666666;
            }
            .el-input {
              @include add-size($font_size_16);
              width: 390px;
            }
            .el-textarea__inner{
              width: 390px;
              @include add-size($font_size_16);
            }
            span{
              @include add-size($font_size_16);
            }
            .el-icon-circle-plus-outline {
              width: 30px;
              display: inline-block;
              font-size: 30px;
              vertical-align: middle;
            }
            .pspan {
              display: inline-block;
              width: 360px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              vertical-align: middle;
              .spanname {
                @include add-size($font_size_16);
                margin-left: 6px;
              }
            }
            .block {
              .el-date-editor--daterange.el-input,
              .el-date-editor--daterange.el-input__inner,
              .el-date-editor--timerange.el-input,
              .el-date-editor--timerange.el-input__inner {
                width: 390px;
              }
            }
            /deep/.el-radio__label {
              @include add-size($font_size_16);
            }
          }
        }
      }
    }
    .btn {
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
}
.addstagedialog {
    /deep/.el-dialog {
      width: 840px;
      border-radius: 10px;
      .el-dialog__header {
        border-radius: 10px 10px 0px 0px;
        background: #3388ff;
        .header-title {
          .title-name {
            display: inline-block;
            width: 10px;
            height: 10px;
            border: 4px solid #ffffff;
            border-radius: 50%;
            vertical-align: middle;
            margin-right: 4px;
            margin-bottom: 5px;
          }
          .title-age {
            vertical-align: top;
            display: inline-block;
            @include add-size($font_size_16);
            color: #ffffff;
          }
        }
        .el-dialog__headerbtn .el-dialog__close {
          color: #fff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
      .body {
        height: 420px;
        .body_table{
          .el-table thead {
            @include add-size($font_size_16);
          }
          .el-table__row {
            @include add-size($font_size_16);
          }
        }
        .el-checkbox__inner {
          width: 20px;
          height: 20px;
        }
        .el-checkbox__inner::after{
          height:10px;
          left:7px;
        }
      }
      .btn {
        text-align: center;
        button:nth-child(1) {
          width: 180px;
          background: #ffffff;
          border: 1px solid #3388ff;
          background: #3388ff;
          height: 50px;
          border-radius: 10px;
          font-size: 24px;
          color: #fff;
          margin-right: 20px;
        }
        button:nth-child(2) {
          width: 180px;
          background: #ffffff;
          border: 1px solid #3388ff;
          height: 50px;
          border-radius: 10px;
          font-size: 24px;
          color: #3388ff;
          margin-right: 20px;
        }
      }
    }
}
.stagedeletedialog {
  /deep/.el-dialog {
    width: 450px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .body {
      height: 70px;
      text-align: center;
      span:nth-child(1) {
        display: inline-block;
        width: 26px;
        line-height: 26px;
        border-radius: 50%;
        background: #ffba00;
        text-align: center;
        color: #fff;
        margin-right: 10px;
        vertical-align: middle;
      }
      span:nth-child(2) {
        display: inline-block;
        vertical-align: middle;
        color: #666666;
        @include add-size1($font_size_18);
      }
    }
    .btn {
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        background: #3388ff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #ffffff;
        margin-right: 20px;
      }
    }
  }
}
.newlyaddedstagedialog {
  /deep/.el-dialog {
    width: 500px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .body {
      // height: 200px;
      .el-form {
        .el-form-item {
          .el-form-item__label {
            @include add-size($font_size_16);
            color: #666666;
          }
          .el-input {
            @include add-size($font_size_16);
            width: 350px;
          }
          .block {
            .el-date-editor--daterange.el-input,
            .el-date-editor--daterange.el-input__inner,
            .el-date-editor--timerange.el-input,
            .el-date-editor--timerange.el-input__inner {
              width: 390px;
            }
          }
          .el-textarea {
            width: 350px;
          }
        }
      }
    }
    .btn {
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
    }
  }
}
.modifystagedialog {
  /deep/.el-dialog {
    width: 500px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .body {
      // height: 200px;
      .el-form {
        .el-form-item {
          .el-form-item__label {
            @include add-size($font_size_16);
            color: #666666;
          }
          .el-input {
            @include add-size($font_size_16);
            width: 350px;
          }
          .block {
            .el-date-editor--daterange.el-input,
            .el-date-editor--daterange.el-input__inner,
            .el-date-editor--timerange.el-input,
            .el-date-editor--timerange.el-input__inner {
              width: 390px;
            }
          }
          .el-textarea {
            width: 350px;
          }
        }
      }
    }
    .btn {
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        background: #3388ff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #ffffff;
        margin-right: 20px;
      }
    }
  }
}
.administratorsdialog {
  /deep/.el-dialog {
    width: 500px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .body {
      text-align: center;
      height: 100px;
    }
    .btn {
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
    }
  }
}
.deleteprojectdialog {
  /deep/.el-dialog {
    width: 450px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .body {
      height: 70px;
      text-align: center;
      span:nth-child(1) {
        display: inline-block;
        width: 26px;
        line-height: 26px;
        border-radius: 50%;
        background: #ffba00;
        text-align: center;
        color: #fff;
        margin-right: 10px;
        vertical-align: middle;
      }
      span:nth-child(2) {
        display: inline-block;
        vertical-align: middle;
        color: #666666;
        @include add-size1($font_size_18);
      }
    }
    .btn {
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        background: #3388ff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #ffffff;
        margin-right: 20px;
      }
    }
  }
}
.Modifyprojectdialog {
  /deep/.el-dialog {
    width: 650px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .body {
      // height: 200px;
      .el-form {
        .el-form-item {
          .el-form-item__label {
            @include add-size($font_size_16);
            color: #666666;
          }
          .el-input {
            @include add-size($font_size_16);
            width: 390px;
          }
          .block {
            .el-date-editor--daterange.el-input,
            .el-date-editor--daterange.el-input__inner,
            .el-date-editor--timerange.el-input,
            .el-date-editor--timerange.el-input__inner {
              width: 390px;
            }
          }
          .el-textarea {
            width: 390px;
          }
        }
      }
    }
    .btn {
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        background: #3388ff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #ffffff;
        margin-right: 20px;
      }
    }
  }
}
.dialog_add1 {
  /deep/ .el-dialog {
    width: 1200px;
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 5px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .body {
      height: 400px;
      .body-left {
        float: left;
        .el-form {
          .el-form-item {
            .el-form-item__label {
              @include add-size($font_size_16);
              color: #666666;
            }
            .el-input {
              @include add-size($font_size_16);
              width: 390px;
            }
          }
        }
      }
      .body-right {
        float: left;
        .el-form {
          .el-form-item {
            .el-form-item__label {
              @include add-size($font_size_16);
              color: #666666;
            }
            .el-input {
              @include add-size($font_size_16);
              width: 390px;
            }
            .el-icon-circle-plus-outline {
              width: 30px;
              display: inline-block;
              font-size: 30px;
              vertical-align: middle;
            }
            .pspan {
              display: inline-block;
              width: 360px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              vertical-align: middle;

              .spanname {
                @include add-size($font_size_16);
                margin-left: 6px;
              }
            }
            .block {
              .el-date-editor--daterange.el-input,
              .el-date-editor--daterange.el-input__inner,
              .el-date-editor--timerange.el-input,
              .el-date-editor--timerange.el-input__inner {
                width: 390px;
              }
            }
            .el-radio__label{
              @include add-size($font_size_16);
            }
          }
        }
      }
    }
    .btn {
      margin-bottom: 14px;
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
}
/deep/ .el-table .el-table__row--level-1 .el-table__indent {
  padding-left: 0px!important;
}
/deep/ .el-table .el-table__row--level-2 .el-table__indent {
  padding-left: 10px!important;
}
.p-hover:hover {
  cursor:pointer
}
</style>

