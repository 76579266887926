import axios from "./Interceptor.js"
import qs from "qs"
let address = window.global_config.BASE_URL
let api = {
    milepostData: (info) => {//返回里程碑列表分页
        return axios({
            method: "post",
            url: `${address}TopicMilestone/GetPaged`,
            data:qs.stringify(info)
        })
    },
    addmilepostData(info){//新增里程碑模块功能
        return axios({
            method: "post",
            url: `${address}TopicMilestone/Insert`,
            data:qs.stringify(info)
        })
    },
    UpdatemilepostData(info){//修改里程碑模块功能
        return axios({
            method: "post",
            url: `${address}TopicMilestone/Update`,
            data:qs.stringify(info)
        })
    },
    DeletemilepostData(id){//删除里程碑模块功能
        return axios({
            method: "post",
            url: `${address}TopicMilestone/Delete`,
            data:qs.stringify(id)
        })
    },
    statemilepostData(info){//更新里程碑模块状态功能
        return axios({
            method: "post",
            url: `${address}TopicMilestone/UpdateFlag`,
            data:qs.stringify(info)
        })
    },
    AppropriationData(TopicId){//返回预算，余额，进度
        return axios({
            method: "get",
            url: `${address}TopicAllocateFunds/AllocateFunds?TopicId=${TopicId}`,
        })
    },
    addAppropriationData(info){//新增拨款进度
        return axios({
            method: "post",
            url: `${address}TopicAllocateFunds/Insert`,
            data:info
        })
    },
    pagingmilepostData(info){//返回拨款列表分页
        return axios({
            method: "post",
            url: `${address}TopicAllocateFunds/GetPaged`,
            data:qs.stringify(info)
        })
    },
    preFinancFile: (DataGuid) => { // 显示文件列表
        return axios({
            method:"get",
            url:`${address}TopicData/GetAll?DataGuid=${DataGuid}`,
        })
    },
    UpdateAppropriationData(info){//编辑拨款进度
        return axios({
            method: "post",
            url: `${address}TopicAllocateFunds/Update`,
            data:info
        })
    },
    DeleteAppropriationData(id){//删除拨款进度
        return axios({
            method: "post",
            url: `${address}TopicAllocateFunds/Delete`,
            data:qs.stringify(id)
        })
    },
    alldelet:(info)=>{//目录文件的删除
        return axios({
            method:"post",
            url:`${address}TopicData/Delete`,
            data:qs.stringify(info),
        })
    },
    previewobj:(id)=>{
        return axios({
            method:"get",
            url:`${address}TopicData/Get?Id=${id}`
        })
    },
    previewarr:(DataGuid)=>{
        return axios({
            method:"get",
            url:`${address}TopicData/GetAll?DataGuid=${DataGuid}`
        })
    },
    achievementsData:(TopicId)=>{//统计概览成果进度
        return axios({
            method:"get",
            url:`${address}TopicInfoStatistics/GetTopicArchiveFile?TopicId=${TopicId}`
        })
    },
    ProjectOverviewData:(TopicId)=>{
        return axios({
            method:"get",
            url:`${address}TopicInfoStatistics/GetTopicInfoSumList?TopicId=${TopicId}`,
        })
    },
    projectallData:()=>{//首页下拉框全部项目
        return axios({
            method:"get",
            url:`${address}TopicInfo/GetAll`,
        })
    },
    // AppropriationprogressData:(TopicId)=>{//拨款进度
    //     return axios({
    //         method:"get",
    //         url:`${address}TopicInfoStatistics/GetTopicAllocateFundsProgressSumList?TopicId=${TopicId}`,
    //     })
    // },
    AppropriationprogressData:(info)=>{//统计拨款记录分页
        return axios({
            method:"post",
            url:`${address}TopicInfoStatistics/GetTopicAllocateFundsProgressSumPaged`,
            data:info
        })
    },
    NumberofcasescollectedData:(TopicId)=>{//病例采集数数据
        return axios({
            method:"get",
            url:`${address}TopicInfoStatistics/GetTopicDataCollectNumberSumList?TopicId=${TopicId}`,
        })
    },
    ExpenditureData:(TopicId)=>{//经费支出概览数据
        return axios({
            method:"get",
            url:`${address}TopicInfoStatistics/GetTopicFinancAccountSumList?TopicId=${TopicId}`,
        })
    },
    secrecyData:(info)=>{//上传保密协议
        return axios({
            method:"post",
            url:`${address}TopicInfo/UploadTopicConfidentiality`,
            data:info
        })
    },
    judgeData:(info)=>{//判断能否进入项目菜单和财务管理菜单
        return axios({
            method:"get",
            url:`${address}TopicMenu/MenuPermission?`,
            params:info
        })
    },
    MenuAllData:(topicId)=>{//判断能否进入项目菜单和财务管理菜单
        return axios({
            method:"get",
            url:`${address}TopicMenu/MenuPermissionAll?topicId=${topicId}`,
        })
    },
    signData:(topicId)=>{//判断当前用户是否签署过保密协议
        return axios({
            method:"get",
            url:`${address}TopicConfidentiality/GetTopicConfidentialityUser?topicId=${topicId}`,
        })
    },
    secrecyfileData:(topicId)=>{//返回保密协议文件列表
        return axios({
            method:"get",
            url:`${address}TopicInfo/GetConfidentialityFile?topicId=${topicId}`,
        })
    },
    SignconfidentialityData:(info)=>{//签署保密协议
        return axios({
            method:"post",
            url:`${address}TopicConfidentiality/SignConfidentiality`,
            data:info
        })
    },
    GetPagesecrecyData:(info)=>{//保密协议列表
        return axios({
            method:"post",
            url:`${address}TopicConfidentiality/GetPaged`,
            data:qs.stringify(info)
        })
    },
    RejectConfidentialityData:(info)=>{//驳回签署的保密协议
        return axios({
            method:"post",
            url:`${address}TopicConfidentiality/RejectConfidentiality`,
            data:qs.stringify(info)
        })
    },
    SmsResetPasswordCodeData:(loginName)=>{
        return axios({
            method:"get",
            url:`${address}Account/SmsResetPasswordCode?loginName=${loginName}`,
        })
    },
    VerifySmsCodeData:(info)=>{
        return axios({
            method:"get",
            url:`${address}Account/VerifySmsCode`,
            params:info
        })
    },
    UpdateResetPasswordData:(info)=>{
        return axios({
            method:"post",
            url:`${address}Account/UpdateResetPassword`,
            data:info
        })
    },
    SysIpData:(info)=>{
        return axios({
            method:"post",
            url:`${address}SysIpData/GetPaged`,
            data:qs.stringify(info)
        })
    },
}
export default api
